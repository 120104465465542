import React, { useState } from "react";
import { Button, Stack, Text, useToast } from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { isEmail } from "@formiz/validations";
import { sendPasswordResetEmail } from "config/firebase";
import { toastError, toastSuccess } from "functions/toast";
import { FieldInput } from "components/fields/FieldInput";

export const FormReseting = ({ setIsReseting }) => {
  const [isLoading, setIsLoading] = useState(false);
  const loginForm = useForm({
    subscribe: "form",
  });

  const toast = useToast();

  const handleBack = () => {
    setIsReseting(false);
  };

  const handleResetPassword = (values) => {
    setIsLoading(true);
    sendPasswordResetEmail(values.login.toLowerCase())
      .then(async () => {
        toastSuccess(toast, {
          title: "Email de récupération envoyé",
        });
        setIsReseting(false);
        setIsLoading(false);
      })
      .catch(() => {
        setIsReseting(false);
        setIsLoading(false);
        toastError(toast, {
          title: "Échec de la demande",
        });
      });
  };

  return (
    <>
      <Formiz connect={loginForm} onValidSubmit={handleResetPassword}>
        <form noValidate onSubmit={loginForm.submit}>
          <Stack spacing="6">
            {/* Formulaire de login */}
            <FieldInput
              name="login"
              type="email"
              required="Requis"
              label="Email"
              validations={[
                {
                  rule: isEmail(),
                  message: "Format email incorrect",
                },
              ]}
            />
            <Button
              isLoading={isLoading}
              type="submit"
              colorScheme="twitter"
              w="100%"
            >
              Réinitialiser
            </Button>
          </Stack>
        </form>
      </Formiz>
      <Text
        as="button"
        my={2}
        color="gray.900"
        textAlign="left"
        fontSize="0.8rem"
        fontStyle="italic"
        textDecoration="underline"
        onClick={handleBack}
        w="100%"
      >
        Se connecter
      </Text>
    </>
  );
};
