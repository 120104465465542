import { Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { useCreateCreneauTableAndReturnCommandeAssociate } from "../services/useCreateCreneauTableAndReturnCommandeAssociate";
import { useFindLastCreneauTableByTable } from "../services/useFindLastCreneauTableByTable";

const PageChargementCheckingForCreateCommande = () => {
  const { restaurantId, tableId } = useParams();
  const { push } = useHistory();

  const { lastCreneauTable, isLoading } =
    useFindLastCreneauTableByTable(tableId);

  const handleCallbackSuccess = async (data) => {
    push(
      `/restaurant/${restaurantId}/table/${tableId}/commande/${data?.creneauTable?.commande?.id}`
    );
  };

  const { mutate: createCreneau, isLoading: isLoadingCreateCreneau } =
    useCreateCreneauTableAndReturnCommandeAssociate({ handleCallbackSuccess });

  if (!isLoading && !isLoadingCreateCreneau) {
    if (lastCreneauTable) {
      if (lastCreneauTable?.endedAt) {
        const formData = new FormData();

        formData.append(
          "datas",
          JSON.stringify({
            tableId,
          })
        );

        // Create query
        createCreneau(formData);

        console.log(lastCreneauTable, "create new");
      } else {
        push(
          `/restaurant/${restaurantId}/table/${tableId}/commande/${lastCreneauTable?.commande?.id}`
        );
        console.log(lastCreneauTable, "redirect to commande");
      }
    } else {
      const formData = new FormData();

      formData.append(
        "datas",
        JSON.stringify({
          tableId,
        })
      );

      // Create query
      createCreneau(formData);

      console.log(lastCreneauTable, "create new");
    }
  }

  return (
    <Stack width="100vw" height="100vh" justify="center" align="center">
      <video loop autoPlay width={200} height={200}>
        <source src="/media/LogoAnimate.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Stack>
  );
};

export default PageChargementCheckingForCreateCommande;
