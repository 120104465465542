import {
  Box,
  HStack,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import NavbarDefault from "components/navbar/NavbarDefault";
import React from "react";
import { Link } from "react-router-dom";

export const LayoutCommande = ({
  children,
  buttonBottomLayout,
  buttonTopLeft,
  buttonTopRight,
}) => {
  const isSmallDevice = useBreakpointValue(
    { base: true, xl: false },
    { ssr: false }
  );

  return (
    <HStack
      minH="100vh"
      height="100%"
      maxWidth="100vw"
      width="100%"
      // flex={1}
      align="flex-start"
    >
      {!isSmallDevice && <NavbarDefault />}

      <Stack
        bgColor="white"
        minHeight="100vh"
        maxWidth="100vw"
        height="100%"
        position="relative"
        flex={1}
        style={{ marginInlineStart: 0 }}
        pl={{ base: "0px", xl: "13rem" }}
      >
        <Box
          px={5}
          py={3}
          mx={2}
          my={2}
          // bgColor="brand.bleuCiel"
          // color="white"
          display="flex"
          // justifyContent={isSmallDevice ? "space-between" : "flex-end"}
          alignItems="center"
        >
          <Box flex={1}>
            {isSmallDevice && buttonTopLeft ? buttonTopLeft : null}
          </Box>

          <Box
            flex={1}
            justifyContent="center"
            align="center"
            as={Link}
            to="/admin/dashboard"
          >
            {/* <Image src="/logo_full.png" h={25} alt="logo" /> */}
            <Text fontWeight="900" fontSize="1.3rem">
              MENU<span style={{color: "orange"}}>FAST</span>
            </Text>
          </Box>
          <Box flex={1} display="flex" alignItems="center" justifyContent="flex-end">
            {isSmallDevice && buttonTopRight ? buttonTopRight : null}
          </Box>
        </Box>
        {buttonBottomLayout}
        <Stack p={5} pt={0} spacing={3} height="100%">
          {children}
        </Stack>
      </Stack>
    </HStack>
  );
};
