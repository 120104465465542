import React from "react";
import { useField } from "@formiz/core";
import { Stack, Input, Text, HStack } from "@chakra-ui/react";

export const FieldInput = (props) => {
  const { errorMessage, id, isValid, setValue, value } = useField(props);
  const {
    label,
    type,
    required,
    mb,
    size,
    w,
    isSubmitted,
    helperText,
    ...rest
  } = props;
  const [isTouched, setIsTouched] = React.useState(false);

  const showError = !isValid && (isTouched || isSubmitted);

  return (
    <Stack mb={mb} spacing={0} w={w}>
      <label htmlFor={id}>
        <Text fontSize={size ?? 11} color="gray.500">
          {label}
          {!!required && " *"}
        </Text>
      </label>
      <Input
        id={id}
        type={type || "text"}
        value={value ?? ""}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => setIsTouched(true)}
        aria-invalid={showError}
        aria-required={!!required}
        aria-describedby={showError ? `${id}-error` : null}
        borderColor={showError ? "red" : null}
        size={size ?? "sm"}
        {...rest}
      />
      <HStack>
        {helperText && (
          <Text
            color="gray.400"
            fontWeight="500"
            fontSize="13px"
            fontStyle="italic"
          >
            {helperText}
          </Text>
        )}
        {showError && (
          <Text color="red" fontWeight="500" fontSize="13px" id={`${id}-error`}>
            {helperText && "-"} {errorMessage}
          </Text>
        )}
      </HStack>
    </Stack>
  );
};
