import PageCommande from "App/commande/pages/PageCommande";
import PageChargementCheckingForCreateCommande from "App/table/pages/PageChargementCheckingForCreateCommande";
import { Authenticated } from "components/Authenticated/Authenticated";
// import Logout from "App/profil/pages/Logout";
import Page404 from "pages/error/Page404";
import PageUnauthorized from "pages/error/PageUnauthorized";
import Logout from "pages/login/Logout";
import { PageLogin } from "pages/login/PageLogin";
import React from "react"; // { useEffect }
import { Switch, Route, Redirect } from "react-router-dom";
// import ReactGA from "react-ga";

const Routes = () => {
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <Switch>
      <Route exact path="/logout" component={Logout} />
      <Route exact path="/login" component={PageLogin} />
      {/* <Route exact path="/logout" component={Logout} /> */}
      <Route exact path="/unauthorized" component={PageUnauthorized} />
      <Route exact path="/404" component={Page404} />
      {/* <Route exact path="/" component={PageComingSoonTest} /> */}

      <Route
        exact
        path="/restaurant/:restaurantId/table/:tableId"
        component={PageChargementCheckingForCreateCommande}
      />

      <Route
        exact
        path="/restaurant/:restaurantId/table/:tableId/commande/:commandeId"
        component={PageCommande}
      />
      <Route
        exact
        path="/admin"
        component={() => <Redirect to="/admin/dashboard" />}
      />
      {/* <Authenticated
        exact
        authorisedUsers={["ROLE_ADMIN"]}
        path="/admin/creators"
        component={PageListeCreators}
      /> */}

      <Route exact path="*" component={() => <Redirect to="/404" />} />
    </Switch>
  );
};

export default Routes;
