// import { useAuthentificationContext } from "context/auth-context";

export const useMenuAdmin = () => {
  // const {
  //   canAccessCoursAppel,
  //   canAccessAbsences,
  //   canAccessClasses,
  //   canAccessEleves,
  // } = useAuthentificationContext();

  const NavAdmin = [
    {
      title: "",
      links: [
        {
          label: "Dashboard",
          icon: "FaThLarge",
          url: "/admin/dashboard",
          canAccess: true,
        },
      ],
    },
    {
      title: "INSCRIPTIONS",
      links: [
        {
          label: "Creators",
          icon: "FaPhotoVideo",
          url: "/admin/creators",
          canAccess: true,
        },
        {
          label: "Clichtons",
          icon: "FaMoneyCheckAlt",
          url: "/admin/clichtons",
          canAccess: true,
        },
      ],
    },
    {
      title: "LANDING PAGE",
      links: [
        {
          label: "Coming Soon",
          icon: "FaLaptop",
          url: "/",
          canAccess: true,
        },
        {
          label: "Formulaire",
          icon: "FaWpforms",
          url: "/creators/formulaire",
          canAccess: true,
        },
      ],
    },
  ];

  return {
    NavAdmin,
  };
};
