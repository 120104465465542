import { useEffect } from "react";

/**
 * Creation du custom hook pour gérer les evenements
 */
export const useGlobalEvents = (props) => {
  const { form, key, type, action } = props;
  useEffect(() => {
    const onEnter = (event) => {
      if (event.key === key && action === "submitForm") {
        form.submit();
      }
    };
    window.addEventListener(type, onEnter, false);
    return () => {
      window.removeEventListener(type, onEnter, false);
    };
  }, [form, type, action, key]);
};
