import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Formiz, useForm } from "@formiz/core";
import { FieldInput } from "components/fields/FieldInput";
import React from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { useHistory, useLocation, useParams } from "react-router-dom";

const ModalNomClient = ({ isOpen, onOpen, onClose }) => {
  const { push } = useHistory();
  const { search } = useLocation();
  const formClient = useForm({ subscribe: "form" });

  const params = new URLSearchParams(search);

  const handleSubmitNomClient = (values) => {
    if (values?.nom && values?.nom !== "") {
        params.set(`clientNom`, values?.nom?.toLowerCase());
        push({
          search: params?.toString(),
        });
        onClose()
    }
  };

  return (
    <Modal isCentered size="xs" isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay  backdropFilter="blur(5px)" />
      <ModalContent>
        <ModalHeader>Je commence la commande en tant que</ModalHeader>
        <ModalBody>
          <Formiz
            autoform
            connect={formClient}
            onValidSubmit={handleSubmitNomClient}
          >
            <FieldInput
            //   defaultValue={client?.nom}
              name="nom"
            />
          </Formiz>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="green"
            leftIcon={<AiFillPlayCircle />}
            onClick={formClient.submit}
          >
            Démarrer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalNomClient;
