import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Heading,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useUpsertCommandeItem } from "App/commandeItem/services/useUpsertCommandeItem";
import React, { useEffect } from "react";
import { useQueryClient } from "react-query";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFindCommandeByIdWithItemsGrouped } from "../services/useFindCommandeByIdWithItemsGrouped";
import ButtonVoirCommande from "./_partials/ButtonVoirCommande";

const DrawerCommande = ({ isOpen, onClose, commande }) => {
  const { commandeId } = useParams();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const queryClient = useQueryClient();

  const handleCallbackSuccess = async (data) => {
    await queryClient.invalidateQueries(["commande-grouped", commandeId]);
  };

  const { mutate: upsertCommandeItem, isLoading: isLoadingUpsertCommandeItem } =
    useUpsertCommandeItem({ handleCallbackSuccess });

  const handleIncrementQuantity = (values) => {
    const datas = {
      itemId: values?.item?.id,
      commandeItemId: values?.id,
      commandeId,
      clientId: values?.client?.id,
      quantite: values?.quantite + 1,
    };
    
    const formData = new FormData();
    formData.append("datas", JSON.stringify(datas));

    // formData.append(
    //   "datas",
    //   JSON.stringify({
    //     datas,
    //   })
    // );

    // Upsert query
    upsertCommandeItem(formData);
  };

  const handleDecrementQuantity = (values) => {
    const datas = {
      itemId: values?.item?.id,
      commandeItemId: values?.id,
      commandeId,
      clientId: values?.client?.id,
      quantite: values?.quantite - 1,
    };
    
    const formData = new FormData();
    formData.append("datas", JSON.stringify(datas));

    // formData.append(
    //   "datas",
    //   JSON.stringify({
    //     datas,
    //   })
    // );

    // Upsert query
    upsertCommandeItem(formData);
  };

  return (
    <>
      {/* <ButtonVoirCommande onOpen={onOpenDrawerCommande} /> */}
      <Drawer
        placement="bottom"
        onClose={onClose}
        isOpen={isOpen}
        blockScrollOnMount
      >
        <DrawerOverlay />
        <DrawerContent
          borderTopRadius="3xl"
          minHeight="80vh"
          boxShadow="0px 2px 18px -1px rgba(0,0,0,0.62)"
          borderTop="1px solid #BABABA"
          py="16px"
        >
          <DrawerBody>
            <HStack align="center">
              <HStack>
                <Text fontSize="1.25rem" color="black" fontWeight="600">
                  Voir la commande
                </Text>
                <Badge>{commande?.numero}</Badge>
              </HStack>
              <DrawerCloseButton />
            </HStack>

            <Stack mt={5} spacing={5}>
              {Object.entries(commande?.commandeItems[0])?.map(
                ([keyCategorie, clients]) => (
                  <Stack key={keyCategorie}>
                    <Stack
                      justify="center"
                      align="center"
                      bgColor="lightGray"
                      p={1}
                      borderRadius="lg"
                    >
                      <Heading size="sm">{keyCategorie}</Heading>
                    </Stack>

                    {Object.entries(clients)?.map(([keyClient, items]) => (
                      <Stack>
                        <Heading size="sm">{keyClient}</Heading>
                        <Divider />
                        <Stack>
                          {items?.map((item) => {
                            const isClient =
                              params?.get("clientNom") ===
                              keyClient?.toLowerCase();

                            return (
                              <HStack
                                justify="space-between"
                                align="flex-start"
                              >
                                <Text fontWeight={600}>{item?.item?.nom}</Text>
                                <Stack spacing={0} align="center">
                                  <ButtonGroup size="sm" isAttached>
                                    <Button
                                      colorScheme="red"
                                      boxShadow="0 0.15rem #dfd9d9"
                                      isDisabled={!isClient}
                                      _hover={{
                                        background: "lightcoral",
                                        color: "white",
                                        textShadow: "0 0.1rem #bcb4b4",
                                      }}
                                      _active={{
                                        color: "white",
                                        boxShadow: "0 0.2rem #dfd9d9",
                                        transform: "translateY(0.2rem)",
                                      }}
                                      onClick={() =>
                                        handleDecrementQuantity(item)
                                      }
                                      // isLoading={isLoadingUpsertCommandeItem}
                                    >
                                      -
                                    </Button>
                                    <Button
                                      cursor="auto"
                                      borderBottom="solid 1px #dfd9d9"
                                    >
                                      {item?.quantite}
                                    </Button>
                                    <Button
                                      colorScheme="green"
                                      boxShadow="0 0.15rem #dfd9d9"
                                      isDisabled={!isClient}
                                      _hover={{
                                        background: "lightcoral",
                                        color: "white",
                                        textShadow: "0 0.1rem #bcb4b4",
                                      }}
                                      _active={{
                                        color: "white",
                                        boxShadow: "0 0.2rem #dfd9d9",
                                        transform: "translateY(0.2rem)",
                                      }}
                                      onClick={() =>
                                        handleIncrementQuantity(item)
                                      }
                                      // isLoading={isLoadingUpsertCommandeItem}
                                    >
                                      +
                                    </Button>
                                  </ButtonGroup>
                                  <Box
                                    width="100%"
                                    py={1}
                                    justifyContent="center"
                                    align="center"
                                    bgColor="#EDF2F7"
                                    borderBottomRadius="0.375rem"
                                  >
                                    <Text fontWeight={600}>{`${
                                      item?.quantite * item?.item?.price
                                    } €`}</Text>
                                  </Box>
                                </Stack>
                              </HStack>
                            );
                          })}
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                )
              )}
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerCommande;

// Créer compte utilisateur qui enregistre la commande pour faciliter les prochaines
