import { Spinner, Stack, Heading } from "@chakra-ui/react";
import { useAuthentificationContext } from "context/auth-context";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Redirect } from "react-router-dom";

const Logout = () => {
  const { logOut } = useAuthentificationContext();
  const [runRedirect, setRunRedirect] = useState(false);

  useEffect(() => {
    logOut();
    const timer = setTimeout(() => {
      setRunRedirect(true);
    }, 2000);
    return () => clearTimeout(timer);
  });

  if (runRedirect) {
    return <Redirect to="/login" />;
  }

  return (
    <Stack justify="center" align="center" w="100vw" h="100vh">
      <Heading size="md">Déconnexion en cours</Heading>
      <Spinner />
    </Stack>
  );
};

export default Logout;
