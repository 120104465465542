import { Stack } from "@chakra-ui/react";
import React from "react";
import { useMenuAdmin } from "./useMenuAdmin";
import ListNav from "./_partials/ListNav";

const NavbarDefault = () => {
  const { NavAdmin } = useMenuAdmin();

  return (
    <Stack
      w="13rem"
      minHeight="100vh"
      height="100%"
      flex={1}
      position="fixed"
      bgColor="white"
      transition="all .7s ease"
      zIndex={100}
      borderRightWidth="1px"
      borderRightStyle="solid"
      borderRightColor="brand.300"
      alignSelf="stretch"
      spacing={5}
      pt={1}
    >
      {NavAdmin?.map((item) => {
        return <ListNav key={item?.title} catNav={item} />;
      })}
    </Stack>
  );
};

export default NavbarDefault;
