import { Stack, Text, Link as LinkChakra } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

const Page404 = () => {
  return (
    <Stack h="100vh" justify="center" align="center">
      <Text textAlign="center" fontSize="40px" fontWeight={700}>
        Erreur 404
      </Text>
      <Text textAlign="center" fontSize="30px" fontWeight={700}>
        Page Introuvable
      </Text>
      <LinkChakra as={Link} to="/">
        {`<- Retour à l'accueil`}
      </LinkChakra>
    </Stack>
  );
};

export default Page404;
