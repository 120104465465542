import React, { useCallback, useEffect, useState } from "react";
import { useAuthentificationContext } from "context/auth-context";
import { useHistory, useLocation } from "react-router-dom";
import { FormLogin } from "./_partials/FormLogin";
import { LayoutLogin } from "App/layout/LayoutLogin";
import { FormReseting } from "./_partials/FormReseting";

export const PageLogin = () => {
  const [isReseting, setIsReseting] = useState(false);
  const [isErrorLogin, setIsErrorLogin] = useState(false);
  const { state } = useLocation();
  const { user, initializing, isError } = useAuthentificationContext();
  const history = useHistory();
  
  const redirecting = useCallback(() => {
    if (!initializing && !isErrorLogin && user !== null) {
      if (isError) {
        return history.push({
          pathname: "/login",
          state: { from: state?.from },
        });
      }
      return history.push({
        pathname:
          state?.from && state?.from !== "/login" ? state?.from : "/accueil",
        state: { from: state?.from },
      });
    }
    return null;
  }, [user, history, state?.from, isError, isErrorLogin, initializing]);

  useEffect(() => {
    redirecting();
    return () => redirecting();
  }, [redirecting]);

  const isFormLogin = user === null && !initializing && !isReseting;
  const isFormReseting = user === null && !initializing && isReseting;

  return (
    <LayoutLogin>
      {isFormLogin && (
        <FormLogin
          setIsReseting={setIsReseting}
          setIsErrorLogin={setIsErrorLogin}
        />
      )}
      {isFormReseting && (
        <FormReseting
          setIsReseting={setIsReseting}
          setIsErrorLogin={setIsErrorLogin}
        />
      )}
    </LayoutLogin>
  );
};
