import { Badge, HStack, Text } from "@chakra-ui/react";
import React from "react";

const ButtonVoirCommande = ({ onOpen, commande }) => {
  return (
    <HStack
      w="100%"
      align="center"
      py="16px"
      px="24px"
      zIndex={2}
      position="fixed"
      bottom={0}
      onClick={onOpen}
      borderTopRadius="3xl"
      boxShadow="0px 2px 18px -1px rgba(0,0,0,0.62)"
      cursor="pointer"
      borderTop="1px solid #BABABA"
    >
      <HStack>
        <Text fontSize="1.25rem" color="black" fontWeight="600">
          Voir la commande
        </Text>
        <Badge>{commande?.numero}</Badge>
      </HStack>
    </HStack>
  );
};

export default ButtonVoirCommande;
