import Axios from "config/axios";
import { useMutation } from "react-query";
import { useToast } from "@chakra-ui/react";
import {
  toastError,
  // toastSuccess
} from "functions/toast";

/**
 * @author Issa Drici <issadricipro@gmail.com>
 * @returns
 */
export const useCreateCreneauTableAndReturnCommandeAssociate = ({
  handleCallbackSuccess,
}) => {
  const toast = useToast();

  return useMutation((data) => Axios.post(`/creneauTable`, data), {
    onSuccess: async (commandeCreated) => {
      if (handleCallbackSuccess !== undefined) {
        handleCallbackSuccess(commandeCreated);
      }
      return true;
    },
    onError: async () => {
      toastError(toast, { title: "Erreur !" });
      return false;
    },
  });
};
