import { useQuery } from "react-query";
import Axios from "config/axios";

export const useFindCommandeByIdWithItemsGrouped = (commandeId) => {
  const { data, isLoading } = useQuery(["commande-grouped", commandeId], () => {
    return Axios.get(`/commande/${commandeId}/grouped`);
  });

  const { commande } = data ?? [];

  return {
    commande,
    isLoading,
  };
};
