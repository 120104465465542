import React, { StrictMode } from "react";
import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';
import '@fontsource/montserrat/900.css';
import "config/dayjs";
import "config/axios";
import "./bodyStyles.css";
import { createRoot } from "react-dom/client";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { customTheme } from "theme/customTheme";
import { ChakraProvider } from "@chakra-ui/react";
// import ReactGA from "react-ga";

// const TRACKING_ID = "UA-242597247-1"; // OUR_TRACKING_ID

// ReactGA.initialize(TRACKING_ID);

const queryClient = new QueryClient();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <ChakraProvider resetCSS theme={customTheme}>
      <QueryClientProvider client={queryClient} >
        {/* <ReactQueryDevtools initialIsOpen={false}  /> */}
        <App />
      </QueryClientProvider>
    </ChakraProvider>
  </StrictMode>
);
