import {
  IconButton,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import ModalNomClient from "App/client/components/ModalNomClient";
import { LayoutCommande } from "App/layout/LayoutCommande";
import React, { useEffect } from "react";
import { FiShare } from "react-icons/fi";
import { TbExchange } from "react-icons/tb";
import { useHistory, useLocation, useParams } from "react-router-dom";
import DrawerCommande from "../components/DrawerCommande";
import ButtonVoirCommande from "../components/_partials/ButtonVoirCommande";
import { useFindCommandeByIdWithItemsGrouped } from "../services/useFindCommandeByIdWithItemsGrouped";

const PageCommande = () => {
  const { push } = useHistory();
  const { tableId, restaurantId, commandeId } = useParams();

  const {
    isOpen: isOpenModalNomClient,
    onOpen: onOpenModalNomClient,
    onClose: onCloseModalNomClient,
  } = useDisclosure();

  const {
    isOpen: isOpenDrawerCommande,
    onOpen: onOpenDrawerCommande,
    onClose: onCloseDrawerCommande,
  } = useDisclosure();

  const { search } = useLocation();
  const params = new URLSearchParams(search);

  const { commande, isLoading } =
    useFindCommandeByIdWithItemsGrouped(commandeId);

  const handleSwitchUser = () => {
    params?.delete("clientNom");
    push({ search: params?.toString() });
  };

  useEffect(() => {
    if (!params?.get("clientNom")) {
      onOpenModalNomClient();
    }
  }, [params]);

  return (
    <LayoutCommande
      buttonBottomLayout={
        !isLoading ? (
          <ButtonVoirCommande
            onOpen={onOpenDrawerCommande}
            commande={commande}
          />
        ) : null
      }
      buttonTopLeft={
        <IconButton
          variant="ghost"
          icon={<FiShare />}
          onClick={() =>
            navigator.share({
              title: `Commande n°${commande?.numero}`,
              url: `/restaurant/${restaurantId}/table/${tableId}/commande/${commandeId}`,
            })
          }
        />
      }
      buttonTopRight={
        <IconButton
          variant="ghost"
          icon={<TbExchange />}
          onClick={handleSwitchUser}
        />
      }
    >
      <Text>
        Vous êtes connecté sur cette commande en tant que{" "}
        {params?.get("clientNom")?.charAt(0).toUpperCase() +
          params?.get("clientNom")?.slice(1)}
      </Text>
      {!isLoading ? (
        <DrawerCommande
          isOpen={isOpenDrawerCommande}
          onClose={onCloseDrawerCommande}
          commande={commande}
        />
      ) : null}

      <ModalNomClient
        isOpen={isOpenModalNomClient}
        onClose={onCloseModalNomClient}
      />
    </LayoutCommande>
  );
};

export default PageCommande;

// Créer compte utilisateur qui enregistre la commande pour faciliter les prochaines
