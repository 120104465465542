import React from "react";
import { Box, Image, Stack } from "@chakra-ui/react";

export const LayoutLogin = ({ children, width }) => {
  return (
    <Box
      minW="100vw"
      minH="100vh"
      // bgColor="gray.300"
      // background="linear-gradient(120deg, #FAF8D4 0%, #B2A3B5 100%)"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Box
        p={8}
        color="gray.500"
        fontWeight="semibold"
        letterSpacing="wide"
        fontSize="xs"
      >
        <Stack align="center" py={5}>
          <Image src="/logo_full.png" h={25} alt="logo" />
        </Stack>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
