import { useQuery } from "react-query";
import Axios from "config/axios";

export const useFindLastCreneauTableByTable = (tableId) => {
  const { data, isLoading } = useQuery(["lastCreneauTable", tableId], () => {
    return Axios.get(`/table/${tableId}/lastCreneauTable`);
  });

  const { lastCreneauTable } = data ?? [];

  return {
    lastCreneauTable,
    isLoading,
  };
};
