import { Button, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";

const PageUnauthorized = () => {
  const history = useHistory();
  
  const handleGoAccueil = () => {
    history.push("/accueil");
  };

  return (
    <Stack h="100vh" justify="center" align="center">
      <Text textAlign="center" fontSize="40px" fontWeight={700}>
        Oups..
      </Text>
      <Text textAlign="center" fontSize="30px" fontWeight={700}>
        Vous n'êtes pas autorisé à visiter cette page !
      </Text>
      <Button onClick={handleGoAccueil}>Retour</Button>
    </Stack>
  );
};

export default PageUnauthorized;
